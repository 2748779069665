import React from 'react'
import blogClasses from '../../PMPblog.module.css'
import Container from '../../../../UI/Container/Container'
import Heading from '../../../../UI/Heading/Heading'
import posts from '../../../../../../data/blog/posts'
import SEO from '../../../../seo'
import Signature from '../Signature/Signature'

const post = posts.filter(post => post.link === 'kanban-explained')[0]

export default () => (
  <Container width={1060}>
    <SEO
      title={`PMP Blog | ${post.title}`}
      keywords={[
        `Kanban`,
        `Project Management Methodology`,
        `Agile Project Management`,
        `PMP Exam`
      ]}
      description="Kanban methodology explained from A to Z. The 4 basic principles of  Kanban. The 6 basic practices of Kanban."
    ></SEO>
    <Heading text={post.title} />
    <div className={blogClasses.Image}>{post.images[0]}</div>
    <br />
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <span className={blogClasses.SubTitle}>History</span>
    </div>
    <p>
      The first Kanban system was developed in the early 1940s by Taiichi Ochno
      for Toyota automotive in Japan. The word 'kanban' means 'visual sign' or
      'card'. Kanban was also referred to as just-in-time manufacturing and was
      introduced to increase productivity while reducing cost-intensive
      inventory. The system helped to avoid supply disruption and overstocking
      of goods at different stages of the manufacturing process creating more
      value for the customer without generating more costs.
    </p>
    <p>
      The Kanban concept was later successfully introduced to other sectors with
      the purpose to change the ways in which products and services were
      delivered.
    </p>
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <span className={blogClasses.SubTitle}>Concept</span>
    </div>
    <p className={blogClasses.NoBottomMargin}>
      Kanban is a change management system when the existing process is improved
      in small rather than large steps, leading to low or no resistance in the
      team and the stakeholders involved. To visualize the workflow, there is a
      Kanban board (a simple whiteboard) and sticky notes or cards. Each card on
      the board represents a task. This visualization provides transparency
      about:
    </p>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>
          <span>the distribution of the work</span>
        </li>
        <li>
          <span>existing bottlenecks</span>
        </li>
      </ul>
    </div>
    <p>
      Once the Kanban board is filled with cards, you can see that some columns
      will get overcrowded with tasks. This will help you spotlight bottlenecks
      in your workflow and address them properly.
    </p>
    <p>
      The simplest and the most classic Kanban board has three columns – 'To
      Do', 'In Progress' and 'Done'. Any company can benefit from applying the
      principles of the Kanban Methodology because when applied, it shows
      bottlenecks within the system and anything that might get in the way of
      smooth working practices.
    </p>
    <div className={blogClasses.Image}>{post.images[1]}</div>
    <p />
    <p>
      Kanban boards can be very elaborate depending on the complexity of the
      workflow. The boards may vary considerably from company to company and
      from project to project. Here is another example of the Kanban board,
      which has more than three basic columns:
    </p>
    <div className={blogClasses.Image}>{post.images[2]}</div>
    <p />
    <p>
      However, in today's world of highly developed technologies, the Kanban
      board is not necessarily the actual board on the wall. There are many
      digital Kanban board solutions that have been developed to modernize the
      work and overcome the problems arising in remote teams.
    </p>
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <span className={blogClasses.SubTitle}>Flow</span>
    </div>
    <p>
      The concept of 'Flow' is essential in Kanban. This means that the cards
      should flow through the system as smoothly as possible. Long waiting times
      or blockages signify that something should be changed in the workflow and
      everything that hinders the flow should be looked into.
    </p>
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <h2 className={blogClasses.SubTitle}>The 4 basic principles of Kanban</h2>
    </div>
    <p>
      The Kanban method gradually improves organization's processes. To increase
      the benefits to your business process, 4 basic principles of Kanban should
      be followed.
    </p>
    <div className={blogClasses.Image}>{post.images[3]}</div>
    <p />
    <div className={blogClasses.List}>
      <ol className={blogClasses.NoBottomMargin}>
        <li>
          <p>
            <b>Start where you are</b>
          </p>
          <p>
            The Kanban method is less prescriptive and less disruptive to begin
            implementing as it is the original 'start where you are' method.
            Kanban methodology should be applied directly to the current
            workflow since it's very flexible. Any necessary changes can be
            applied gradually at a pace the team is comfortable with.
          </p>
        </li>
        <li>
          <p>
            <b>Agree to pursue incremental, evolutionary change</b>
          </p>
          <p>
            Sweeping changes usually encounter resistance due to fear or
            uncertainty, therefore, they are discouraged. Kanban encourages to
            make small incremental changes rather than making radical changes
            that might lead to resistance within the team and organization.
          </p>
        </li>
        <li>
          <p>
            <b>Respect current roles, responsibilities and job titles</b>
          </p>
          <p>
            This principle helps the organizations overcome the typical
            emotional resistance and the fear of change. Kanban recognizes that
            existing roles, responsibilities and job titles have value and are
            worth preserving. The Kanban method does not encourage changes as
            long as existing roles and functions are performing well.
          </p>
        </li>
        <li>
          <p>
            <b>Encourage acts of leadership at all levels</b>
          </p>
          <p>
            Kanban encourages continuous improvement at all levels of the
            organization and new ideas may come from any member of the team,
            regardless of the position or status.
          </p>
        </li>
      </ol>
    </div>
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <h3 className={blogClasses.SubTitle}>The 6 basic practices of Kanban</h3>
    </div>
    <p>
      There are six basic practices that need to be present for the successful
      implementation of the Kanban Method.
    </p>
    <div className={blogClasses.Image}>{post.images[4]}</div>
    <p />
    <div className={blogClasses.List}>
      <ol className={blogClasses.NoBottomMargin}>
        <li>
          <p>
            <b>Visualize the Workflow</b>
          </p>
          <p>
            You need to visualize, whether on a physical board or an electronic
            Kanban Board. To visualize your process with a Kanban system, you
            will need a board with cards and columns, where each column
            represents a step in your workflow and each Kanban card represents a
            work item. Different colors of stickies or cards may signify either
            different types of service or work. The progress can be easily
            tracked as the cards are moved from 'To do' column to 'Done' column.
          </p>
        </li>
        <li>
          <p>
            <b>Limit Work in Progress (WIP)</b>
          </p>
          <p>
            Limiting work-in-progress (also referred to as a 'Pull-system') is
            fundamental while implementing the Kanban Method. By limiting WIP,
            you minimize multitasking and encourage your team to complete work
            at hand first before taking up new tasks. Setting maximum items per
            stage (column) ensures that a card is only 'pulled' into the next
            step when there is available capacity. However, it may not be easy
            to decide what your WIP limits should be initially. In Kanban
            method, it's more important to complete work than it is to start a
            new one. Therefore, the teams should adhere to WIP limits and get
            each piece of work through the system to 'done' column.
          </p>
        </li>
        <li>
          <p>
            <b>Manage Flow</b>
          </p>
          <p>
            The whole idea of implementing a Kanban system is to create a smooth
            healthy flow of work items through the production process. Depending
            on how well the workflow is defined, the team will observe either a
            smooth flow within WIP limits or work piling up in one column.
            Kanban helps your team analyze the system and make adjustments to
            improve the workflow.
          </p>
        </li>
        <li>
          <p>
            <b>Make Process Policies Explicit</b>
          </p>
          <p>
            The team would not take part in something they do not believe is
            useful. By formulating explicit process guidelines, you create a
            common basis for all team members to understand how to do any type
            of work. The policies can be at the board level, at a swim lane
            level or for each column. The policies must be defined explicitly
            and visualized usually on the top of the board and on each lane and
            column. Here is a simple example of the board where the guidelines
            are explicitly defined.
          </p>
          <div className={blogClasses.Image}>{post.images[5]}</div>
          <p />
        </li>
        <li>
          <p>
            <b>Feedback Loops</b>
          </p>
          <p>
            Feedback loops are necessary for knowledge transfer. They lead to
            delivering the right work, the right product to the customer in the
            shortest possible time.
          </p>
        </li>
        <li>
          <p>
            <b>Improve Collaboratively</b>
          </p>
          <p>
            Continuous improvement and sustainable change within an organization
            can be achieved through a shared image of possible improvements and
            a collective understanding of the problems that need to be dealt
            with. The Kanban Method is an evolutionary improvement process. It
            helps you adopt small changes and improve gradually at a pace and
            size that your team can handle easily.
          </p>
          <p className={blogClasses.NoBottomMargin}>
            Kanban can't solve all of the problems, but it makes the lives of
            project managers easier. There are many benefits to use the Kanban
            Method but I will limit them to the most significant ones:
          </p>
          <div className={blogClasses.List}>
            <ul className={blogClasses.NoBottomMargin}>
              <li>
                <span>
                  Flexibility - teams are usually not bound by timeboxes
                </span>
              </li>
              <li>
                <span>
                  Increased productivity and quality - Limiting WIP helps
                  increase productivity and quality
                </span>
              </li>
              <li>
                <span>
                  Focus on continuous delivery - teams are focused on flowing
                  work through the system to completion and not starting new
                  work until work in progress is completed
                </span>
              </li>
              <li>
                <span>
                  Reduction of waste - transparency makes waste visible and easy
                  to remove
                </span>
              </li>
              <li>
                <span>
                  Collaboration – Kanban improves the way people work together
                </span>
              </li>
              <li>
                <span>
                  Rapid feedback loops - improve the chances of more motivated
                  and better-performing team members
                </span>
              </li>
            </ul>
          </div>
        </li>
      </ol>
    </div>

    <Signature date={post.date} author={post.author} />
  </Container>
)
